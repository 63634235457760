import { inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthUserService } from '../../../../auth/data-access/auth-user.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private toastrService = inject(ToastrService);
  private authUserService = inject(AuthUserService);

  handleUnauthorizedError(error: HttpErrorResponse): void {
    if (!this.authUserService.isAuthenticated()) return;
    this.toastrService.error(
      this.getErrorMessage(
        error,
        'Twoja sesja wygasła. Zostaniesz przekierowany na stronę logowania za 3 sekundy.',
      ),
      'Sesja wygasła',
    );
    setTimeout(() => {
      this.authUserService.logout();
    }, 3000);
  }

  handleForbiddenError(error: HttpErrorResponse) {
    this.toastrService.error(
      this.getErrorMessage(
        error,
        'Nie masz uprawnień do dostępu do tego zasobu. Skontaktuj się z administratorem.',
      ),
      'Zabronione',
    );
  }

  handleNotFoundError(error: HttpErrorResponse) {
    this.toastrService.error(
      this.getErrorMessage(error, 'Żądany zasób nie został znaleziony.'),
      'Nie znalezniono',
    );
  }

  handleInternalServerError(error: HttpErrorResponse) {
    this.toastrService.error(
      this.getErrorMessage(error, 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.'),
      'Wewnętrzny błąd serwera',
    );
  }

  handleBadRequestError(error: HttpErrorResponse) {
    this.toastrService.error(
      this.getErrorMessage(error),
      this.getErrorTitle(error),
    );
  }

  getErrorTitle(error: HttpErrorResponse) {
    if (error.error.title) {
      return this.getError1Title(error.error as IError1);
    } else {
      return 'Nieprawidłowe żądanie';
    }
  }

  getErrorMessage(
    error: HttpErrorResponse,
    fallbackMessage = 'Żądanie było nieprawidłowe. Spróbuj ponownie.',
  ) {
    if (error.error.title) {
      return this.getError1Message(error.error as IError1, fallbackMessage);
    } else {
      return this.getError2Message(error.error as IError2, fallbackMessage);
    }
  }

  getError1Title(error: IError1) {
    return error.title;
  }

  getError1Message(error: IError1, fallbackMessage: string) {
    return error.errors[Object.keys(error.errors)[0]][0] || fallbackMessage;
  }

  getError2Message(error: IError2, fallbackMessage: string) {
    return error.errors[0]?.message || fallbackMessage;
  }
}

export interface IError1 {
  type: string;
  title: string;
  status: number;
  errors: {
    [key: string]: string[];
  };
  traceId: string;
}

export interface IError2 {
  isSingleError: boolean;
  errors: {
    message: string;
    field: string;
    metadata: any;
  }[];
  detailedErrors: null;
}
