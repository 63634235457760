import {MatPaginatorIntl} from '@angular/material/paginator';

const polishRangeLabel = (page: number, pageSize: number, length: number): string => {
  if (length === 0 || pageSize === 0) {
    return `0 z ${length}`;
  }

  const startIndex = page * pageSize;
  const endIndex = Math.min(startIndex + pageSize, length);

  return `${startIndex + 1} - ${endIndex} z ${length}`;
};

export function getPolishPaginatorIntl(): MatPaginatorIntl {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Elementy na stronę:';
  paginatorIntl.nextPageLabel = 'Następna strona';
  paginatorIntl.previousPageLabel = 'Poprzednia strona';
  paginatorIntl.getRangeLabel = polishRangeLabel;

  return paginatorIntl;
}
