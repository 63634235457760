import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { ToastComponent } from './shared/ui/toast/toast.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { tokenInterceptor } from './shared/utils/interceptors/token.interceptor';
import { provideToastr } from 'ngx-toastr';
import { errorsInterceptor } from './shared/utils/service/errors/errors.interceptor';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getPolishPaginatorIntl} from './shared/utils/polish-pagination.intl';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideToastr({
      toastComponent: ToastComponent,
    }),
    provideAngularSvgIcon(),
    provideHttpClient(withInterceptors([tokenInterceptor, errorsInterceptor])),
    { provide: MatPaginatorIntl, useValue: getPolishPaginatorIntl() },
  ],
};


