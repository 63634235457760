import { computed, inject, Injectable, signal } from '@angular/core';
import { AuthUserSate, TokenState } from './auth.model';
import {
  AuthApiService,
  ChangePasswordRequest,
  LoginRequest,
  RememberPasswordRequest,
} from '../../shared/utils/api/auth-api.service';
import {
  catchError,
  filter,
  map,
  of,
  share,
  shareReplay,
  Subject,
  switchMap,
  take,
} from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { CookieStorageService } from '../../shared/utils/service/cookie-storage.service';
import {
  REMEMBER_DEVICE_TOKEN_COOKIE,
  TOKEN_COOKIE_KEY,
} from '../../shared/utils/constants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  private authService = inject(AuthApiService);
  private cookiesService = inject(CookieStorageService);
  private router = inject(Router);

  public is2FA = signal<boolean>(false);

  #authState = signal<TokenState>({
    token: null,
    processing: false,
  });

  #authUserState = signal<AuthUserSate>({
    user: null,
    isAuthenticated: false,
    processing: false,
  });

  public isAuthenticated = computed(() => {
    return this.#authUserState().isAuthenticated;
  });

  public user = computed(() => {
    return this.#authUserState().user;
  });

  public token = computed(() => {
    return this.#authState().token;
  });

  public token$ = toObservable(this.token);
  private getUser$ = this.token$.pipe(
    filter((token) => !!token),
    switchMap(() =>
      this.authService.getUserInfo().pipe(
        map((response) => response.result),
        catchError(() => of(null)),
      ),
    ),
    shareReplay(1),
  );

  loginFromToken = signal<boolean>(false);

  private loginSource = new Subject<LoginRequest>();
  private login$ = this.loginSource.pipe(
    map((request) => {
      const rememberDeviceToken = this.readRememberDeviceCookie(request.login);

      if (!rememberDeviceToken) return request;

      return {
        ...request,
        rememberDeviceToken,
      };
    }),
    switchMap((request) =>
      this.authService.postLogin(request).pipe(
        map((response) => ({
          response,
          login: request.login,
          is2FA: false,
        })),
        catchError((error) => {
          if (error.status === 403)
            return of({ response: null, login: null, is2FA: true });
          return of(null);
        }),
      ),
    ),
    filter((response) => !!response),
    share(),
  );

  public login(request: LoginRequest) {
    this.loginSource.next(request);
  }

  public rememberPassword(request: RememberPasswordRequest) {
    return this.authService.rememberPasswordForInvestor(request);
  }

  public changePassword(request: ChangePasswordRequest) {
    return this.authService.changePassword(request);
  }

  constructor() {
    this.loginSource.pipe(takeUntilDestroyed()).subscribe(() => {
      this.#authState.update((state) => ({
        ...state,
        processing: true,
      }));
    });

    this.login$
      .pipe(
        filter((res) => res.is2FA),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.is2FA.set(true);
      });
    this.login$
      .pipe(
        filter((res) => !res.is2FA),
        takeUntilDestroyed(),
      )
      .subscribe((res) => {
        if (!res?.response) return;

        const user = res.response.result;

        this.#authState.update((state) => ({
          ...state,
          token: user.token,
          isAuthenticated: true,
          processing: false,
        }));

        this.setTokenCookies(res.login, user.token, user.rememberDeviceToken);

        this.is2FA.set(false);
      });

    this.getUser$.pipe(takeUntilDestroyed()).subscribe((user) => {
      this.#authUserState.update((state) => ({
        ...state,
        user,
        isAuthenticated: !!user,
        processing: false,
      }));

      if (!!user && !this.loginFromToken()) this.router.navigate(['/']);
    });
  }

  public setTokenCookies(
    login: string,
    token: string,
    rememberDeviceToken: string,
  ) {
    this.cookiesService.save(TOKEN_COOKIE_KEY, token, 1);
    this.saveRememberDeviceCookie(login, rememberDeviceToken);
  }

  public getTokenFromCookies() {
    return this.cookiesService.read<string>(TOKEN_COOKIE_KEY);
  }

  saveRememberDeviceCookie(login: string, token: string) {
    const cookies = this.getRememberDeviceCookies();

    this.cookiesService.save(
      REMEMBER_DEVICE_TOKEN_COOKIE,
      {
        ...cookies,
        [login]: token,
      },
      400,
    );
  }

  getRememberDeviceCookies() {
    return this.cookiesService.read<Record<string, string>>(
      REMEMBER_DEVICE_TOKEN_COOKIE,
    );
  }

  public readRememberDeviceCookie(login: string) {
    const cookies = this.getRememberDeviceCookies();

    return cookies?.[login];
  }

  public tryAuthorizeFromCookies(navigate: boolean = false) {
    const token = this.getTokenFromCookies();
    this.loginFromToken.set(!navigate);

    if (!token) this.navigateToLogin();

    this.#authState.update((state) => ({
      ...state,
      token,
    }));
  }

  public handleUnauthenticated() {
    this.tryAuthorizeFromCookies();
    return this.getUser$.pipe(
      take(1),
      map((user) => {
        if (!user) {
          this.navigateToLogin();
          return false;
        }

        return true;
      }),
    );
  }

  public logout() {
    this.cookiesService.remove(TOKEN_COOKIE_KEY);
    this.#authState.update((state) => ({
      ...state,
      token: null,
      isAuthenticated: false,
    }));
    this.#authUserState.update((state) => ({
      ...state,
      user: null,
      isAuthenticated: false,
    }));
    this.navigateToLogin();
  }

  private navigateToLogin() {
    console.log('navigate to login');
    this.router.navigate(['/auth/login']);
  }
}
