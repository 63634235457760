import { Routes } from '@angular/router';
import { authGuard } from './auth/utils/auth.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    path: 'login',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.routes').then((r) => r.AuthRoutes),
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./application/application.routes').then(
        (r) => r.ApplicationRoutes,
      ),
    canMatch: [authGuard],
    canActivate: [authGuard],
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];
