import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeInOut = (targetOpacity: number) => {
  return trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate('500ms', style({ opacity: targetOpacity })),
    ]),
    transition('* => void', [animate('500ms', style({ opacity: 0 }))]),
  ]);
};
export enum ROTATE_ANIMATION {
  Starting = 'starting',
  Rotated = 'rotated',
}

export const rotate = (
  startDeg: number,
  endDeg: number,
  duration: number = 500,
) => {
  return trigger('rotate', [
    state(
      ROTATE_ANIMATION.Starting,
      style({ transform: `rotate(${startDeg}deg)` }),
    ),
    state(
      ROTATE_ANIMATION.Rotated,
      style({ transform: `rotate(${endDeg}deg)` }),
    ),
    transition(`${ROTATE_ANIMATION.Starting} => ${ROTATE_ANIMATION.Rotated}`, [
      animate(`${duration}ms ease-in-out`),
    ]),
    transition(`${ROTATE_ANIMATION.Rotated} => ${ROTATE_ANIMATION.Starting}`, [
      animate(`${duration}ms ease-in-out`),
    ]),
    transition(`void => ${ROTATE_ANIMATION.Starting}`, [
      style({ transform: `rotate(${startDeg}deg)` }),
      animate(`${duration}ms ease-in-out`),
    ]),
  ]);
};
