import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';

export const errorsInterceptor: HttpInterceptorFn = (req, next) => {
  const errorHandlerService = inject(ErrorHandlerService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      switch (error.status) {
        case 400:
          errorHandlerService.handleBadRequestError(error);
          break;
        case 401:
          errorHandlerService.handleUnauthorizedError(error);
          break;
        case 403:
          errorHandlerService.handleForbiddenError(error);
          break;
        case 404:
          errorHandlerService.handleNotFoundError(error);
          break;
        case 500:
          errorHandlerService.handleInternalServerError(error);
          break;
        default:
          errorHandlerService.handleBadRequestError(error);
      }
      return throwError(() => error);
    }),
  );
};
