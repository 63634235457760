import { Injectable, Renderer2, RendererFactory2, signal } from '@angular/core';

export type Theme = 'theme-light' | 'theme-dark' | 'theme-high-contrasts';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  #currentTheme = signal<Theme>('theme-light');
  public currentTheme = this.#currentTheme.asReadonly();

  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setTheme(theme: 'theme-light' | 'theme-dark' | 'theme-high-contrasts') {
    this.renderer.removeClass(document.body, this.#currentTheme());
    this.#currentTheme.set(theme);
    this.renderer.addClass(document.body, this.#currentTheme());
  }

  getTheme(): Theme {
    return this.#currentTheme();
  }

  changeTheme(theme: Theme) {
    this.setTheme(
      theme as 'theme-light' | 'theme-dark' | 'theme-high-contrasts',
    );
  }

}
